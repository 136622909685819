<template lang="pug">
.container.text-left
  .min-h-full.py-52.max-w-3xl.mx-auto
    svgIcon.p-1.logo(:path="mdiEmail", type="mdi", :size="240")
    H1Element.mb-8 Contacto
    p Puedes ponerte en contacto con nosotros cuando lo necesites.
    p Intentaremos responder en menos de 24 horas.
    p En el caso de necesitar planes de cantidades más grandes no dudes en ponerte en contacto.
    h3 {{ $t('support_email') }}
</template>
<script>
import H1Element from "@/components/elements/h1.element";
import { mdiEmail } from "@mdi/js";
export default {
  name: "contact",
  components: {
    H1Element,
  },
  data: () => ({
    mdiEmail,
  }),
};
</script>
<style lang="scss" scoped>
.logo {
  position: absolute;
  opacity: 0.05;
  margin-left: -120px;
  transform: rotate(-35deg);
}
p {
  @apply text-sm text-gray-500;
}
p + p {
  margin-top: 0.5rem;
}

p + h3 {
  margin-top: 1rem;
}

h3 {
  @apply text-gray-400 font-bold text-xl;
}
</style>